body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: calc(100vh - 50px);
}

.text-with-line {
  border-right: 10px solid #AD1C42;
  border-left: 10px solid #AD1C42;
  border-top: 20px solid #AD1C42;
  border-bottom: 40px solid #AD1C42;
  margin-right: 10px;
}
ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: #AD1C42;
  font-weight: 900;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
